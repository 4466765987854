@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hero-styles.css */
.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: 2rem;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.hero-content button {
  background-color: #000;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border: none;
  cursor: pointer;
}

/* hero-styles.css */
.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 0 0 rgba(255, 165, 0, 0); /* Initial transparent shadow */
  transition: box-shadow 0.1s ease;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
  }
  .hero-content {
    max-width: 90%;
    margin: 0 0 2rem;
  }
}

.hero-content {
  max-width: 40%;
  margin-right: 5rem;
  display: flex;
  flex-direction: column; /* This is the crucial line */
  align-items: flex-start; /* Align items to the left */
}

/* ... other styles */

.no-cc-text {
  margin-top: 5px;
  font-size: 0.75rem;
  color: #777;
}